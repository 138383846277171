import { version } from './version';

export const environment = {
  BASE_URL: 'https://decmatao.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3529302',
  schema: 'pbi_dte_matao',
  groupId: '68760dcf-048a-4373-a630-ae6271f0de3b',
  analiseGeral: '67014fac-15b7-433a-ada0-57ca7ae5cafd',
  analiseUsuarios: 'fd2cb9eb-c068-4b07-b5fd-1569b482805f',
  analiseComunicados: '72aae526-24a1-4f36-8129-aef940309301',
  analiseProcurador: '7e6274a4-2dbd-438d-bd2f-17d2b0eb2cf3',
  analiseEventos: '9c5e1781-8d82-4ac8-900a-ddfad4666176',
  analiseContribuintes: '067ed439-b52d-41f4-8feb-d087ccb0bb18'
};
